<template>
  <div id="container">

    <section id="mypage">
      <div class="wrap">

        <div class="cts_box">
          <h2 class="sub_ttl"><span>塾生クラスの変更</span></h2>
          <p class="lead_txt">
            このページではクラス・お支払い方法の変更のお申込みを受け付けております。<br>
            クラス・お支払い方法の変更内容によって、変更が適用される時期などが異なります。詳しくは以下をご確認ください。
          </p>

          <p class="lead_txt red bold" v-if="current_user.sns_rank === 'rank_student'">
            ※現在「学生科」クラスをご利用中です。ここから「予科」「本科」「本科プラス」いずれかのクラスへのアップグレードを行った場合、「学生科」クラスへの再変更はできません。ご注意下さい。
          </p>

          <template v-if="current_user.rank_bank_fee">
            <p class="lead_txt red bold large">※お支払い方法に銀行振込をご利用の方は、こちらのページからの塾生クラスの変更はできません。<br>下の「お問い合わせ」ボタンからお問い合わせいただくか、複眼経済塾事務局までお電話（03-6280-8451）にてお問い合わせください。</p>
            <div class="button_wrap mt20">
              <p class="alnC bold mb10">こちらのボタンからお問い合わせください</p>
              <router-link to="/contacts/" class="submit basic arrow large">お問い合わせ</router-link>
            </div>
            <p class="alnC sp_alnL bold mt20 mb10">
              お問い合わせはお電話でも承っております<br class="sp">（受付時間 平日 10：00〜17：00）
            </p>
            <div class="contact_tel">
              <a href="tel:0362808451" class="flex">
                <span class="icon_tel">03-6280-8451</span>
              </a>
            </div>
          </template>

          <template v-else>
            <p class="lead_txt red bold" v-if="!current_user.payment_monthly">
              ※現在、お客様は<span class="marker" style="background: linear-gradient(transparent 0%, #faff00 0%);padding: 2px;">【年額払いプラン】</span>をご利用中です。<br><br>
              <span class="marker" style="background: linear-gradient(transparent 0%, #faff00 0%);padding: 2px;">【本科<span class="blue">年額</span>払いプラン】から【本科プラス<span class="blue">年額</span>払いプラン】へのクラス・アップグレードは即時可能です。</span><br>
              この場合、ご登録クレジットカードにより<span class="blue">「差額決済」</span>を行います。<br><br>
              <span class="marker" style="background: linear-gradient(transparent 0%, #faff00 0%);padding: 2px;">一方、【本科<span class="blue">年額</span>払いプラン】から【本科プラス<span class="blue">月額</span>払いプラン】への変更は、<span class="blue">即時に行う事ができません。</span></span><br>
              これは、年額払いの特性上、残りの月数分の月謝残金との差額決済がシステム上では困難なためです。<br><br>
              もし、【本科<span class="blue">年額</span>払い】から【本科プラス<span class="blue">月額</span>払い】への即時アップグレードをご希望の場合は、<br class="pc">お手数ですが<span class="marker" style="background: linear-gradient(transparent 0%, #faff00 0%);padding: 2px;"><span class="blue">事務局</span>まで<span class="blue">お問い合わせフォーム</span>または<span class="blue">お電話（03-6280-8451、受付は平日10時～17時）</span>にてご連絡下さい。</span><br>
              ※【本科プラス<span class="blue">月額</span>払い】から【本科<span class="blue">年額</span>払い】への即時ダウングレードはできません。
            </p>
            <div class="change_contact flex" >
              <p>クラス・お支払い方法の変更についてご不明な点や相談したいことがある場合は、こちらの「お問い合わせ」ボタンよりお知らせください。</p>
              <div>
                <router-link to="/contacts/" class="submit light skeleton">お問い合わせ</router-link>
              </div>
            </div>
          </template>
        </div>

        <div class="cts_box">
          <h2 class="min_ttl"><span>現在のご利用内容</span></h2>
          <template v-if="current_user.sns_rank === 'rank_student'">
            <div class="stdts_details flex">
              <div class="class flex">
                <span class="label">あなたの現在のクラスは</span>
                <div class="class_name flex"><span>学生科</span><p>です</p></div>
              </div>
              <div class="payment flex">
                <div class="price">&yen;2,000 / 月</div>
                <p>※月額払いのみ。消費税込み。</p>
              </div>
            </div>
            <div class="stdts_upgrade_cts">
              <h3>学生科でご利用いただける内容</h3>
              <p class="list">学生科限定の講義<br>学生科限定のイベント<br>ワークショップ学生科料金<br>学生科限定のSNSスペース</p>
              <div class="arrow"><img src="/common/images/arrow_stdts_upgrade.svg" alt="アップグレード"></div>
              <p>下記の変更を希望するクラスの「このクラスに変更」ボタンを押してください。</p>
              <p class="caution">※「社会人クラス」にアップグレードした場合「学生科」へのダウングレードはできません。</p>
            </div>
          </template>
          <template v-else>
            <div class="usage_details flex">
              <div class="class"><span>現在のクラス</span>{{ current_user.rank_label }}</div>
              <div class="payment"><span>お支払い方法</span>{{ current_user.payment_monthly ? '月額払い' : '年額払い' }}</div>
              <div class="update" v-if="!current_user.payment_monthly">
                <span>次回の更新月</span>{{ moment(current_user.next_payment_date, 'YYYY年MM月') }}
              </div>
            </div>
          </template>
        </div>

        <div class="cts_box" v-if="current_user.sns_rank === 'rank_regular_default' || current_user.old_rank_default">
          <h2 class="min_ttl"><span>「会社四季報ONLINE」優待プランのご案内</span></h2>
          <p class="blue bold size20">
            本科生（旧月謝適用中）の方に向けた「会社四季報ONLINE」の優待プラン（月払い）をご用意しております。優待プランへの加入を希望される方はこちらのボタンからお進みください。
          </p>
          <div class="btn_wrap mt20">
            <router-link :to="{name: 'UsersCustomerRankChangeDefault'}" class="submit light h_auto sp_h_auto" v-bind:class="{disabled: current_user.rank_bank_fee}">「会社四季報ONLINE」<br>オプションの追加・変更
            </router-link>
          </div>
        </div>

        <div class="cts_box" v-if="current_user.sns_rank === 'rank_regular'">
          <h2 class="min_ttl"><span>「会社四季報ONLINE」オプション</span></h2>
          <p class="blue bold size20">
            本科の方は「会社四季報ONLINE」の塾生割引をご利用いただけます。年割プランとなりますので、月謝のお支払いを月額払いの方は年額払いにご変更ください。
          </p>
          <div class="btn_wrap mt20">
            <router-link :to="{name: 'UsersCustomerRankChangeNew', params: {new_rank: 'regular'}}" class="submit light h_auto sp_h_auto" v-bind:class="{disabled: current_user.rank_bank_fee}">「会社四季報ONLINE」<br>オプションの追加・変更
            </router-link>
          </div>
        </div>

        <div class="cts_box sp_mb" v-if="current_user.sns_rank !== 'rank_student'">
          <h2 class="min_ttl"><span>変更したいクラスを選択してください</span></h2>
          <p v-if="current_user.payment_monthly">
            変更を希望するクラスの「このクラスに変更」または「支払い方法を変更」ボタンを押してください。
          </p>
          <p v-else>
            変更を希望するクラスの「このクラスを予約」または「支払い方法の変更を予約」ボタンを押してください。
          </p>
        </div>

        <div class="stdts_tag sp" v-if="current_user.sns_rank === 'rank_student'">社会人クラス</div>

        <div class="cts_box">
          <div class="matrix flex">

            <div class="matrix_col pc">
              <div class="matrix_cell class_select al_c">
                <div class="stdts_tag" v-if="current_user.sns_rank === 'rank_student'">社会人クラス</div>
              </div>
              <div class="matrix_cell flex gaiyo-title">
                <h4>概要</h4>
              </div>
              <div class="matrix_cell flex">
                <h4>月例会</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>当塾の主軸の講座。毎月開催。渡部清二塾長の四季報分析や時事解説・ライブ配信など。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>懇親会</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>毎月開催される月例会の懇親会。渡部清二塾長や他の塾生の方と一緒に会食します。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>各ワークショップ</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>基本的ノウハウを教える勉強会。渡部清二塾長の「四季報10倍」「日経マジ読み」など。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>録画講義</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>初級～上級まで約300本の動画講義を配信。渡部清二塾長、小笹メディア局長がダイレクトに発信する通信等。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>複眼IRミーティング</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>複眼経済塾が有望と感じる企業の社長をお招きし、事業に対する思いや将来の展望を語っていただいています。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>コンテスト</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>塾生の方だけが応募できる、懸賞コンテストです。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>合宿・ツアー</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>講師・塾生と泊まり込みで交流・学びを深めるイベント。投資友達ができます。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>東洋経済会社四季報ONLINE</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>「会社四季報」のオンライン版。複眼経済塾の塾生だけの特別割引があります。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>プラス限定講義</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>本科プラス限定の講義です。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell flex">
                <h4>プラス限定イベント</h4>
                <div class="info"><span>詳細を見る</span>
                  <div class="info_box">
                    <p>本科プラス限定のイベントへの参加です。前夜祭・ゴルフコンペなど。</p>
                    <div class="colse_btn"></div>
                  </div>
                </div>
              </div>
              <div class="matrix_cell class_select">
              </div>
            </div><!--//matrix_col pc-->

            <!--予科-->
            <div class="matrix_col" v-bind:class="{selected: current_user.sns_rank === 'rank_preparatory'}">
              <!--自分のクラスの時 selected -->
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>予科</h3>
                  <p class="select_txt top">ただいまご利用のクラスです</p>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">4,950<span>円</span></div>
                    <div class="left pc">&nbsp;</div>
                    <div class="right pc">&nbsp;</div>
                    <span class="month_price pc">&nbsp;</span>
                  </div>
                  <p class="caution">※消費税込み。</p>
                  <router-link :to="{name: 'UsersCustomerRankChangeNew', params: {new_rank: 'preparatory'}}"
                               class="btn" v-bind:class="{disabled: current_user.sns_rank === 'rank_preparatory' || current_user.rank_bank_fee}">
                    <template v-if="current_user.sns_rank === 'rank_preparatory'">選択できません</template>
                    <template v-else-if="current_user.sns_rank === 'rank_student'">このクラスに変更</template>
                    <template v-else>このクラスを予約</template>
                  </router-link><!--予科は月額しかないのでdisabled追加-->
                  <template v-if="isDowngrade('rank_preparatory')"><p class="change_time">{{ moment(current_user.next_payment_date, 'YYYY年M月') }}クラス変更</p></template>
                  <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">初級の方、これから投資を始める方など、基礎を学びたい方の講義に特化したコース。基礎コースのため、複眼経済塾の主軸講義である「月例会」等はご覧いただけません。まずは初歩を学んでから本科に進みたい方にお薦め。
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>当塾の主軸の講座。毎月開催。渡部清二塾長の四季報分析や時事解説・ライブ配信など。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>毎月開催される月例会の懇親会。渡部清二塾長や他の塾生の方と一緒に会食します。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>基本的ノウハウを教える勉強会。渡部清二塾長の「四季報10倍」「日経マジ読み」など。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>別途料金</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級～上級まで約300本の動画講義を配信。渡部清二塾長、小笹メディア局長がダイレクトに発信する通信等。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>一部のみ</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾が有望と感じる企業の社長をお招きし、事業に対する思いや将来の展望を語っていただいています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>塾生の方だけが応募できる、懸賞コンテストです。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>講師・塾生と泊まり込みで交流・学びを深めるイベント。投資友達ができます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>「会社四季報」のオンライン版。複眼経済塾の塾生だけの特別割引があります。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラス限定の講義です。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラス限定のイベントへの参加です。前夜祭・ゴルフコンペなど。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>予科</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">4,950<span>円</span></div>
                      <div class="left pc">&nbsp;</div>
                      <div class="right pc">&nbsp;</div>
                      <span class="month_price pc">&nbsp;</span>
                    </div>
                    <p class="caution">※消費税込み。</p>
                    <router-link :to="{name: 'UsersCustomerRankChangeNew', params: {new_rank: 'preparatory'}}"
                                 class="btn" v-bind:class="{disabled: current_user.sns_rank === 'rank_preparatory' || current_user.rank_bank_fee}">
                      <template v-if="current_user.sns_rank === 'rank_preparatory'">選択できません</template>
                      <template v-else-if="current_user.sns_rank === 'rank_student'">このクラスに変更</template>
                      <template v-else>このクラスを予約</template>
                    </router-link><!--予科は月額しかないのでdisabled追加-->
                    <template v-if="isDowngrade('rank_preparatory')"><p class="change_time">{{ moment(current_user.next_payment_date, 'YYYY年M月') }}クラス変更</p></template>
                  </div>
                </div>
                <p class="select_txt">ただいまご利用のクラスです</p>
              </div>
            </div><!--//matrix_col 予科-->

            <!--本科-->
            <div class="matrix_col"
                 v-bind:class="{selected: current_user.sns_rank === 'rank_regular' || current_user.sns_rank === 'rank_regular_default'}">
              <!--自分のクラスの時 selected-->
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3 v-if="current_user.sns_rank === 'rank_regular_default' || current_user.old_rank_default">本科（旧月謝適用中）</h3>
                  <h3 v-else>本科</h3>
                  <p class="select_txt top">ただいまご利用のクラスです</p>
                  <div class="price flex" v-if="current_user.sns_rank !== 'rank_regular_default' && !current_user.old_rank_default">
                    <div class="left">月額払い</div>
                    <div class="right">16,500<span>円</span></div>
                    <div class="left">年額払い</div>
                    <div class="right">162,000<span>円</span></div>
                    <span class="month_price">（月額換算：13,500円）</span>
                  </div>
                  <div class="price flex" v-else>
                    <div class="left">月額払い</div>
                    <div class="right">10,000<span>円</span></div>
                    <div class="left">&nbsp;</div>
                    <div class="right">&nbsp;</div>
                    <span class="month_price">&nbsp;</span>
                  </div>
                  <p class="caution">※消費税込み。</p>
                  <router-link :to="{name: current_user.old_rank_default ? 'UsersCustomerRankChangeDefault' : 'UsersCustomerRankChangeNew' , params: {new_rank: 'regular'}}" class="btn"
                               v-bind:class="{disabled: current_user.sns_rank === 'rank_regular_default' || current_user.rank_bank_fee}">
                    <template v-if="current_user.sns_rank === 'rank_regular_default'">選択できません</template>
                    <template v-else-if="current_user.sns_rank === 'rank_regular' && current_user.payment_monthly">支払い方法の変更</template>
                    <template v-else-if="current_user.sns_rank === 'rank_regular'">支払い方法の変更を予約</template>
                    <template v-else-if="isDowngrade('rank_regular')">このクラスを予約</template>
                    <template v-else>このクラスに変更</template>
                  </router-link>
                  <!--自分がこのクラスの場合、テキストは「支払い方法の変更」になる-->
                  <template v-if="isDowngrade('rank_regular')"><p class="change_time">{{ moment(current_user.next_payment_date, 'YYYY年M月') }}クラス変更</p></template>
                  <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">複眼の主軸コース。塾生の８割がこのコースに在籍。最重要講義である「月例会」や「複眼ポートフォリオレビュー」など、投資を実践するための講義が満載。合宿、ツアーや懇親会も本科より参加いただけます。</div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>当塾の主軸の講座。毎月開催。渡部清二塾長の四季報分析や時事解説・ライブ配信など。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>毎月開催される月例会の懇親会。渡部清二塾長や他の塾生の方と一緒に会食します。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>基本的ノウハウを教える勉強会。渡部清二塾長の「四季報10倍」「日経マジ読み」など。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>別途料金</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級～上級まで約300本の動画講義を配信。渡部清二塾長、小笹メディア局長がダイレクトに発信する通信等。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾が有望と感じる企業の社長をお招きし、事業に対する思いや将来の展望を語っていただいています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>塾生の方だけが応募できる、懸賞コンテストです。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>講師・塾生と泊まり込みで交流・学びを深めるイベント。投資友達ができます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>「会社四季報」のオンライン版。複眼経済塾の塾生だけの特別割引があります。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>追加料金</span></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラス限定の講義です。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラス限定のイベントへの参加です。前夜祭・ゴルフコンペなど。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3 v-if="current_user.sns_rank === 'rank_regular_default' || current_user.old_rank_default">本科（旧月謝適用中）</h3>
                    <h3 v-else>本科</h3>
                    <div class="price flex" v-if="current_user.sns_rank !== 'rank_regular_default' && !current_user.old_rank_default">
                      <div class="left">月額払い</div>
                      <div class="right">16,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">162,000<span>円</span></div>
                      <span class="month_price">（月額換算：13,500円）</span>
                    </div>
                    <div class="price flex" v-else>
                      <div class="left">月額払い</div>
                      <div class="right">10,000<span>円</span></div>
                      <div class="left">&nbsp;</div>
                      <div class="right">&nbsp;</div>
                      <span class="month_price">&nbsp;</span>
                    </div>
                    <p class="caution">※消費税込み。</p>
                    <router-link :to="{name: current_user.old_rank_default ? 'UsersCustomerRankChangeDefault' : 'UsersCustomerRankChangeNew' , params: {new_rank: 'regular'}}" class="btn"
                                 v-bind:class="{disabled: current_user.sns_rank === 'rank_regular_default' || current_user.rank_bank_fee}">
                      <template v-if="current_user.sns_rank === 'rank_regular_default'">選択できません</template>
                      <template v-else-if="current_user.sns_rank === 'rank_regular' && current_user.payment_monthly">支払い方法の変更</template>
                      <template v-else-if="current_user.sns_rank === 'rank_regular'">支払い方法の変更を予約</template>
                      <template v-else-if="isDowngrade('rank_regular')">このクラスを予約</template>
                      <template v-else>このクラスに変更</template>
                    </router-link>
                    <template v-if="isDowngrade('rank_regular')"><p class="change_time">{{ moment(current_user.next_payment_date, 'YYYY年M月') }}クラス変更</p></template>
                  </div>
                </div>
                <p class="select_txt">ただいまご利用のクラスです</p>
              </div>
            </div><!--//matrix_col 本科-->

            <!--本科プラス-->
            <div class="matrix_col" v-bind:class="{selected: current_user.sns_rank === 'rank_regular_plus'}">
              <!--自分のクラスの時 selected-->
              <div class="matrix_cell class_select">
                <div class="inner">
                  <h3>本科プラス</h3>
                  <p class="select_txt top">ただいまご利用のクラスです</p>
                  <div class="price flex">
                    <div class="left">月額払い</div>
                    <div class="right">27,500<span>円</span></div>
                    <div class="left">年額払い</div>
                    <div class="right">264,000<span>円</span></div>
                    <span class="month_price">（月額換算：22,000円）</span>
                  </div>
                  <p class="caution">※消費税込み。</p>
                  <router-link :to="{name: 'UsersCustomerRankChangeNew', params: {new_rank: 'regular_plus'}}" class="btn" v-bind:class="{disabled: current_user.rank_bank_fee}">
                    <template v-if="current_user.sns_rank === 'rank_regular_plus' && current_user.payment_monthly">支払い方法の変更</template>
                    <template v-else-if="current_user.sns_rank === 'rank_regular_plus'">支払い方法の変更</template>
                    <template v-else-if="isDowngrade('rank_regular_plus')">このクラスを予約</template>
                    <template v-else>このクラスに変更</template>
                  </router-link>
                  <template v-if="isDowngrade('rank_regular_plus')"><p class="change_time">{{ moment(current_user.next_payment_date, 'YYYY年M月') }}クラス変更</p></template>
                  <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                </div>
              </div>
              <div class="sp_open_cts">
                <div class="matrix_cell flex">
                  <div class="gaiyo-text">最上級コース。全てのワークショップが追加料金無しで受け放題。全イベント優先申込。プラス限定イベントと講義に、四季報ONLINEプレミアムが付いて、このお値段はお得！最もディープに複眼を使いこなしたいなら本科プラス。</div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>月例会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>当塾の主軸の講座。毎月開催。渡部清二塾長の四季報分析や時事解説・ライブ配信など。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>懇親会</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>毎月開催される月例会の懇親会。渡部清二塾長や他の塾生の方と一緒に会食します。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>各ワークショップ</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>基本的ノウハウを教える勉強会。渡部清二塾長の「四季報10倍」「日経マジ読み」など。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>録画講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>初級～上級まで約300本の動画講義を配信。渡部清二塾長、小笹メディア局長がダイレクトに発信する通信等。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>複眼IRミーティング</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>複眼経済塾が有望と感じる企業の社長をお招きし、事業に対する思いや将来の展望を語っていただいています。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>コンテスト</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>塾生の方だけが応募できる、懸賞コンテストです。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>合宿・ツアー</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>講師・塾生と泊まり込みで交流・学びを深めるイベント。投資友達ができます。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>東洋経済会社四季報ONLINE</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>「会社四季報」のオンライン版。複眼経済塾の塾生だけの特別割引があります。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定講義</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラス限定の講義です。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell flex">
                  <div class="left sp">
                    <h4>プラス限定イベント</h4>
                    <div class="info"><span>詳細を見る</span>
                      <div class="info_box">
                        <p>本科プラス限定のイベントへの参加です。前夜祭・ゴルフコンペなど。</p>
                        <div class="colse_btn"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                </div>
                <div class="matrix_cell class_select pc">
                  <div class="inner">
                    <h3>本科プラス</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">27,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">264,000<span>円</span></div>
                      <span class="month_price">（月額換算：22,000円）</span>
                    </div>
                    <p class="caution">※消費税込み。</p>
                    <router-link :to="{name: 'UsersCustomerRankChangeNew', params: {new_rank: 'regular_plus'}}" class="btn" v-bind:class="{disabled: current_user.rank_bank_fee}">
                      <template v-if="current_user.sns_rank === 'rank_regular_plus' && current_user.payment_monthly">支払い方法の変更</template>
                      <template v-else-if="current_user.sns_rank === 'rank_regular_plus'">支払い方法の変更</template>
                      <template v-else-if="isDowngrade('rank_regular_plus')">このクラスを予約</template>
                      <template v-else>このクラスに変更</template>
                    </router-link>
                    <template v-if="isDowngrade('rank_regular_plus')"><p class="change_time">{{ moment(current_user.next_payment_date, 'YYYY年M月') }}クラス変更</p></template>
                  </div>
                </div>
                <p class="select_txt">ただいまご利用のクラスです</p>
              </div>
            </div><!--//matrix_col 本科プラス-->

          </div>

          <ul class="caution_num" v-if="false">
            <li>四季報10倍ワークショップは教室版とオンライン版があり、価格が異なります。詳細は<a href="https://www.millioneyes.jp/workshop_shikiho/" target="_blank">こちら</a>をご覧ください。
            </li>
            <li>新型コロナウィルス感染拡大防止の為開催されない場合があります。</li>
          </ul>

        </div>

        <template v-if="current_user.sns_rank === 'rank_student'">
          <div class="cts_box">
            <h2 class="min_ttl"><span>「学生科」クラスからの変更を行った場合</span></h2>
            <div class="payment_notice flex">
              <div class="payment_notice_box year">
                <ul class="dotted">
                  <li class="ttl"><h4>クラス変更を行った場合</h4></li>
                  <li>変更は即時に適用され、新しいクラスでのコンテンツ利用・イベント参加が可能となります。</li>
                  <li>現在のご利用中のクラスとの月額差額代金をその場で決済いたします。</li>
                  <li><span class="red bold">アップグレード後の「学生科」クラスへの再変更はできません。</span></li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="cts_box">
            <h2 class="min_ttl"><span>月額払いでご利用の場合</span></h2>

            <div class="payment_notice flex">
              <div class="payment_notice_box upgrade">
                <ul class="dotted">
                  <li class="ttl"><h4>現在のクラスより上位のクラスに変更</h4></li>
                  <li>変更は即時に適用され、新しいクラスでのコンテンツ利用・イベント参加が可能となります。</li>
                  <li>現在のご利用中のクラスとの月額差額代金をその場で決済いたします。日割りでの差額分の決済には対応しておりません。</li>
                </ul>
              </div>
              <div class="payment_notice_box downgrade">
                <ul class="dotted">
                  <li class="ttl"><h4>現在のクラスより下位のクラスに変更</h4></li>
                  <li>変更の申し込みを行った月の翌月1日より新しいクラスでのコンテンツ利用・イベント参加が適用されます。</li>
                  <li>変更の申し込みを行った翌月から新しいお支払い内容が適用されます。日割りでの差額の返金には対応しておりません。</li>
                </ul>
                <p class="caution">※下位クラスに変更した場合、一部ご覧頂けなくなるコンテンツが発生する場合があります。</p>
              </div>
            </div>
          </div>

          <div class="cts_box">
            <h2 class="min_ttl"><span>年額払いでご利用の場合</span></h2>
            <div class="payment_notice flex">
              <div class="payment_notice_box year">
                <ul class="dotted">
                  <li class="ttl"><h4>クラス・お支払い方法を変更</h4></li>
                  <li>ご利用中の年額払い充当期間の末日まで現在のクラスでのコンテンツ利用・イベント参加の条件が適用されます。</li>
                  <li>現在のお支払い期間が終了した月の翌月1日より新しいクラスのお支払い内容が適用されます。</li>
                  <li>日割りならびに月割り等での差額の返金には対応しておりません。</li>
                </ul>
                <p class="caution">※下位クラスに変更した場合、一部ご覧頂けなくなるコンテンツが発生する場合があります。</p>
              </div>
            </div>
          </div>
        </template>

        <div class="btn_wrap">
          <p class="alnC sp_alnL mb10">クラス・お支払い方法の変更をしない場合はこちらのボタンからマイページにお戻りください。</p>
          <router-link :to="{name: 'UsersCustomerAccount'}" class="submit light skeleton arrow back large">マイページにもどる
          </router-link>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link>
        </li>
        <li><a href="#">塾生クラスの変更</a></li>
      </ul>
    </div>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  methods: {
    isDowngrade(new_rank) {
      const scores = {
        rank_regular_plus: 2,
        rank_regular: 1,
        rank_regular_default: 1,
        rank_preparatory: 0,
      }
      return (scores[new_rank] - scores[this.current_user.sns_rank]) < 0
    },
  },

  mounted() {
    // form token
    this.setFormToken('RankChange')

    this.$store.state.change_rank_info = null
    // for jquery.
    window.$(".matrix .info span").on("click", function () {
      if (window.$(this).hasClass("open")) {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
        window.$(this).removeClass("open");
      } else {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeIn(300);
        window.$(this).addClass("open");
      }
    });
    window.$(".matrix .info_box .colse_btn,.matrix .info_box a").on("click", function () {
      window.$(this).closest(".matrix_cell").find(".info span").removeClass("open");
      window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
    })
    window.$(".matrix .tggle_btn").on("click", function () {
      window.$(this).closest(".matrix_col");
      window.$(this).closest(".matrix_col").find(".sp_open_cts").slideToggle();
      window.$(this).closest(".matrix_col").toggleClass("open");
    });
  }
}
</script>
